import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import moment from 'moment-timezone';
// import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class TranslationService {
  defaultLang = 'en';

  constructor(
    private translate: TranslateService
    // private route: ActivatedRoute
    // @Inject(PLATFORM_ID) private platformId: Object
  ) {
    /*if (isPlatformBrowser(this.platformId)) {
      const savedLang = localStorage.getItem('lng');
      if (savedLang) {
        this.defaultLang = savedLang;
      }
      this.translateService.setDefaultLang(this.defaultLang);
      this.translateService.use(this.defaultLang);
    }*/

    this.translate.setDefaultLang(environment.defaultLang);
    // this.translate.use(this.translate.getBrowserLang());

    // Mirar si esta guardado en cookie el idioma
    if(sessionStorage.getItem('language') ) {
      this.changeLang(sessionStorage.getItem('language'));
    // } else if( environment.langs.includes(this.translate.getBrowserLang() ?? environment.defaultLang) ) {
    //  this.changeLang(this.translate.getBrowserLang() ?? environment.defaultLang);
    } else {
      this.changeLang((environment.defaultLang));
    }
    moment.tz.setDefault("Europe/Madrid");
  }

  changeLang(language: string | null) {
    /*if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('language', lang);
    }*/
    if (language && environment.langs.includes(language)) {
      if (language==='br') {
        language = 'pt';
      }
    }
    else {
      language = environment.defaultLang
    }
    this.translate.use(language)
    sessionStorage.setItem('language', language);
  }

  getCurrentLang() {
    return this.translate.currentLang;
  }
}
