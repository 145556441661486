export const environment = {
  production: false,
  defaultRequestLang: 'es',
  defaultLang: 'es',
  langs: ['es','en','br','pt'],
  telegramTipsterpageErrorPayment: 'https://t.me/tipsterpage',
  urlBaseAminTipsterpage: 'https://tipsterpage-dev.web.app',
  urlBaseCheckoutPageOrder: 'https://process-checkout.korvusinc.com',
  apiCheckoutEkans: {
    url: 'https://apiekans.korvusinc.com/apicheckout',
    authBearer: 'KebnrhWG^J4Ev~ywC^;*SH5?C[)AEc{f1[W-mR*{c3az%cw(n9]tJUDr2DPVf#Gs!>GR}Y3aC%xBVgt21;ufDQQyC]E-m6qh',
  },
};
