import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'checkout/:purchaseTokenKey',
    loadComponent: () =>
      import('./checkout/checkout.component')
          .then(m => m.CheckoutComponent),
  },
  {
    path: ':lang/checkout/:purchaseTokenKey',
    loadComponent: () =>
      import('./checkout/checkout.component')
          .then(m => m.CheckoutComponent),
  },
  {
    path: 'success-payment/:paymentMethodType/:orderTempUid',
    loadComponent: () =>
      import('./success-payment/success-payment.component')
          .then(m => m.SuccessPaymentComponent),
  },
  {
    path: 'error-payment/:orderTempUid',
    loadComponent: () =>
      import('./error-payment/error-payment.component')
          .then(m => m.ErrorPaymentComponent),
  },
  {
    path: 'pending-payment/:orderTempUid',
    loadComponent: () =>
      import('./pending-payment/pending-payment.component')
          .then(m => m.PendingPaymentComponent),
  },
  {
    path: 'pending-refund-payment/:orderTempUid',
    loadComponent: () =>
      import('./pending-refund-payment/pending-refund-payment.component')
          .then(m => m.PendingRefundPaymentComponent),
  },
  {
    path: 'download/order-complete-lottery-pack-ticket-pdf/:orderCompleteUid',
    loadComponent: () =>
      import('./download//order-complete-lottery-pack-ticket-pdf/order-complete-lottery-pack-ticket-pdf.component')
          .then(m => m.OrderCompleteLotteryPackTicketPdfComponent),
  },
  {
    path: 'download/order-complete-receipt-pdf/:orderCompleteUid',
    loadComponent: () =>
      import('./download//order-complete-receipt-pdf/order-complete-receipt-pdf.component')
          .then(m => m.OrderCompleteReceiptPdfomponent),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./error404/error404.component')
        .then(m => m.Error404Component),
  },
];
